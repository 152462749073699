<template>
    <base-layout class="min-h-screen">
        <div class="mb-4 flex justify-center items-center flex-col ">
            <check-mark class="w-64"/>
            <h1 class="text-2xl font-bold text-gray-600 text-center mt-6 leading-normal">ການສ້າງຮູບປ້າຍ <b>Check-in
                One</b> ຂອງຮ້ານທ່ານ ສຳເລັດແລ້ວ</h1>
            <div class="mt-4 space-y-2">
                <div class="flex space-x-3 items-start ">
                    <checked-icon class="mt-1.5 transform scale-125 ml-1"/>
                    <h3 class=" text-gray-600 text-lg leading-8 ">
                        ນຳຮູບປ້າຍໄປພິມຕິດໃສ່ຫນ້າຮ້ານທ່ານເພື່ອໃຫ້ລູກຄ້າ Check-in ຮ້ານທ່ານໄດ້ງ່າຍໆ</h3>
                </div>

                <div class="flex space-x-3 items-start ">
                    <checked-icon class="mt-1.5 transform scale-125 ml-1"/>
                    <div class=" text-gray-600 text-lg leading-8 flex flex-col space-y-4">
                        ລູກຄ້າສາມາດ Check-in ຮ້ານທ່ານໂດຍ
                        <div class="mt-1">
                            <div class="flex items-start space-x-2">
                                <div
                                    class="number-circle">
                                    1
                                </div>
                                <div class="text-gray-500 leading-relaxed mt-0.5">
                                    <div class="items-start flex flex-wrap ">
                                        ເຂົ້າ BCEL One
                                        <div class="mx-2 pt-2">
                                            <arrow-icon/>
                                        </div>
                                        ກົດປຸ່ມ Check-In
                                    </div>
                                </div>
                            </div>

                            <div class="flex items-start space-x-2 mt-2">
                                <div
                                    class="number-circle">
                                    2
                                </div>
                                <div class="flex text-gray-500 leading-relaxed mt-0.5">
                                    <div class="items-start flex flex-wrap">
                                        ກົດປຸ່ມ ເຊັກອິນດ້ວຍ QR
                                        <div class="mx-2 pt-2">
                                            <arrow-icon/>
                                        </div>
                                        ສະແກນປ້າຍ QR Check-in One
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <check-in-one-promo class=" w-64 lg:w-72"/>
            <div class="mt-4 text-gray-500 text-center">ມີບັນຫາໃນການດາວໂຫລດຮູບປ້າຍບໍ? ກົດ <a
                class="mx-1 font-bold text-red-500 hover:underline" :href="downloadLink">ບ່ອນນີ້</a> ເພື່ອດາວໂຫລດອີກຄັ້ງ
            </div>
            <div class="mt-4">
                <router-link to="/"
                             class="bg-gradient-to-tr focus:ring focus:ring-blue-200 focus:ring-offset-2 from-red-600 hover:translate-y-2 translate to-red-400 text-white font-bold flex items-center px-6 py-2 rounded-lg shadow hover:shadow-md">

                    <!--                    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
                    <!--                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
                    <!--                         class="feather feather-home mr-2">-->
                    <!--                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>-->
                    <!--                        <polyline points="9 22 9 12 15 12 15 22"></polyline>-->
                    <!--                    </svg>-->
                    ກັບຫນ້າຫລັກ
                </router-link>
            </div>
        </div>
    </base-layout>
</template>

<script>
import BaseLayout from "../components/BaseLayout";
import CheckMark from "../components/CheckMark";
import CheckedIcon from "./CheckedIcon";
// import QrIcon from "./QrIcon";
import ArrowIcon from "./ArrowIcon";
import CheckInOnePromo from "./CheckInOnePromo";

export default {
    name: "Success",
    components: {CheckInOnePromo, ArrowIcon, /*QrIcon,*/ CheckedIcon, CheckMark, BaseLayout},
    computed: {
        qr() {
            const {
                query: {
                    qr
                }
            } = this.$route
            if (!qr) return null
            return qr
        },
        name() {
            const {
                query: {
                    name
                }
            } = this.$route
            if (!name) return null
            return name
        },
        downloadLink() {
            const url = "https://bcel.la:8083/checkinoneqr/generate.php?";
            return url + encodeURI(`qr=${this.qr}&name=${this.name}`)
        }
    },
    watch: {
        $route(route) {
            this.checkQr(route)
        }
    },
    mounted() {
        console.log(this.qr, this.name)
        this.checkQr(this.$route)

        this.$nextTick(() => {
            window.scrollTo(0,0)
        })
    },
    methods: {
        checkQr(route) {
            const {
                query: {
                    qr, name
                }
            } = route
            if (!qr || !name) this.$router.push("/")

        },
        downloadQrImage() {
            window.location.href = this.downloadLink
        }
    }
}
</script>

<style lang="scss" scoped>
.number-circle {
    @apply /*bg-gradient-to-tr from-gray-500 to-gray-300*/
    bg-blue-400 mt-1  w-6 font-bold h-6 flex-shrink-0 rounded-full flex items-center justify-center text-white;
    font-size: .95rem;
}
</style>
