<template>
    <svg class="w-6 h-6 flex-none" fill="none">
        <circle cx="12" cy="12" r="12" fill="#A7F3D0"></circle>
        <path d="M18 8l-8 8-4-4" stroke="#047857" stroke-width="2"></path>
    </svg>
</template>
<script>
export default {
    name: 'checked-icon'
}
</script>
