<template>
    <div class=" flex justify-center">
        <div class="max-w-2xl w-full py-4 px-3 pb-8">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseLayout"
}
</script>

<style scoped>

</style>
